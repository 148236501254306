<template>
    <div>
        <b-form-group
            :class="{ row, error: v$.trip.$errors.length }"
            :label="$t('forms.trip')"
            label-for="trip"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-radio v-model="trip" :name="$t('forms.trip')" value="Starts">
                Starts
            </b-form-radio>
            <b-form-radio v-model="trip" :name="$t('forms.trip')" value="Ends">
                Ends
            </b-form-radio>
            <div
                class="input-errors"
                v-for="error of v$.trip.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
            <div class="small">
                If Trip Ends, the field "Money spent during trip" will appear.
            </div>
        </b-form-group>
        <b-form-group
            :class="{ row, error: v$.plateNumber.$errors.length }"
            :label="$t('forms.plateNumber')"
            label-for="plateNumber"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="plateNumber" :name="$t('forms.plateNumber')"/>
            <div
                class="input-errors"
                v-for="error of v$.plateNumber.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <b-form-group
            :class="{ row, error: v$.counterStatus.$errors.length }"
            :label="$t('forms.counterStatus')"
            label-for="counterStatus"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="counterStatus" :name="$t('forms.counterStatus')"/>
            <div
                class="input-errors"
                v-for="error of v$.counterStatus.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <b-form-group
            :class="{ row, error: v$.moneyLeft.$errors.length }"
            :label="$t('forms.moneyLeft')"
            label-for="moneyLeft"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="moneyLeft" :name="$t('forms.moneyLeft')"/>
            <div
                class="input-errors"
                v-for="error of v$.moneyLeft.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <b-form-group
            v-if="trip === 'Ends'"
            class="row"
            :label="$t('forms.moneySpent')"
            label-for="moneySpent"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="moneySpent" :name="$t('forms.moneySpent')"/>
        </b-form-group>
        <b-form-group
            :class="{ row, error: v$.floppyDisks.$errors.length }"
            :label="$t('forms.floppyDisks')"
            label-for="floppyDisks"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="floppyDisks" :name="$t('forms.floppyDisks')"/>
            <div
                class="input-errors"
                v-for="error of v$.floppyDisks.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <hr>
        <p>
            If NO entered in questions from 1-8, please put details
        </p>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.stockStatus.$errors.length }"
                    :label="$t('forms.stockStatus')"
                    label-for="stockStatus"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="stockStatus"
                        :name="$t('forms.stockStatus')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="stockStatus"
                        :name="$t('forms.stockStatus')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.stockStatus.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.itemsMarked.$errors.length }"
                    :label="$t('forms.itemsMarked')"
                    label-for="itemsMarked"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="itemsMarked"
                        :name="$t('forms.itemsMarked')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="itemsMarked"
                        :name="$t('forms.itemsMarked')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.itemsMarked.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.toolingCondition.$errors.length }"
                    :label="$t('forms.toolingCondition')"
                    label-for="toolingCondition"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="toolingCondition"
                        :name="$t('forms.toolingCondition')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="toolingCondition"
                        :name="$t('forms.toolingCondition')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.toolingCondition.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.equipmentServicable.$errors.length }"
                    :label="$t('forms.equipmentServicable')"
                    label-for="equipmentServicable"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="equipmentServicable"
                        :name="$t('forms.equipmentServicable')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="equipmentServicable"
                        :name="$t('forms.equipmentServicable')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.equipmentServicable.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.officeClean.$errors.length }"
                    :label="$t('forms.officeClean')"
                    label-for="officeClean"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="officeClean"
                        :name="$t('forms.officeClean')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="officeClean"
                        :name="$t('forms.officeClean')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.officeClean.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.carCondition.$errors.length }"
                    :label="$t('forms.carCondition')"
                    label-for="carCondition"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="carCondition"
                        :name="$t('forms.carCondition')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="carCondition"
                        :name="$t('forms.carCondition')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.carCondition.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.tiresCondition.$errors.length }"
                    :label="$t('forms.tiresCondition')"
                    label-for="tiresCondition"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="tiresCondition"
                        :name="$t('forms.tiresCondition')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="tiresCondition"
                        :name="$t('forms.tiresCondition')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.tiresCondition.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-form-group
            :class="{ row, error: v$.otherDamage.$errors.length }"
            :label="$t('forms.otherDamage')"
            label-for="otherDamage"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="otherDamage" :name="$t('forms.otherDamage')"/>
            <div
                class="input-errors"
                v-for="error of v$.otherDamage.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.carFillup.$errors.length }"
                    :label="$t('forms.carFillup')"
                    label-for="carFillup"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="carFillup"
                        :name="$t('forms.carFillup')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="carFillup"
                        :name="$t('forms.carFillup')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.carFillup.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.printerPaper.$errors.length }"
                    :label="$t('forms.printerPaper')"
                    label-for="printerPaper"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="printerPaper"
                        :name="$t('forms.printerPaper')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="printerPaper"
                        :name="$t('forms.printerPaper')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.printerPaper.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.discrepancy.$errors.length }"
                    :label="$t('forms.discrepancy')"
                    label-for="discrepancy"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="discrepancy"
                        :name="$t('forms.discrepancy')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="discrepancy"
                        :name="$t('forms.discrepancy')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.discrepancy.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.onlineTraining.$errors.length }"
                    :label="$t('forms.onlineTraining')"
                    label-for="onlineTraining"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                    <b-form-radio
                        v-model="onlineTraining"
                        :name="$t('forms.onlineTraining')"
                        value="Yes"
                    >
                        Yes
                    </b-form-radio>
                    <b-form-radio
                        v-model="onlineTraining"
                        :name="$t('forms.onlineTraining')"
                        value="No"
                    >
                        No
                    </b-form-radio>
                    <div
                        class="input-errors"
                        v-for="error of v$.onlineTraining.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    class="row"
                    :label="$t('forms.comment')"
                    label-for="comment"
                    label-cols-md="2"
                    content-cols-lg="8"
                >
                    <b-form-input :name="$t('forms.comment')"/>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
    components: {},
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            trip: {required},
            plateNumber: {required},
            counterStatus: {required},
            moneyLeft: {required},
            floppyDisks: {required},
            stockStatus: {required},
            itemsMarked: {required},
            toolingCondition: {required},
            equipmentServicable: {required},
            officeClean: {required},
            carCondition: {required},
            tiresCondition: {required},
            otherDamage: {required},
            carFillup: {required},
            printerPaper: {required},
            discrepancy: {required},
            onlineTraining: {required}
        };
    },
    data() {
        return {
            trip: null,
            plateNumber: null,
            counterStatus: null,
            moneyLeft: null,
            moneySpent: null,
            floppyDisks: null,
            stockStatus: null,
            itemsMarked: null,
            toolingCondition: null,
            equipmentServicable: null,
            officeClean: null,
            carCondition: null,
            tiresCondition: null,
            otherDamage: null,
            carFillup: null,
            printerPaper: null,
            discrepancy: null,
            onlineTraining: null
        };
    },
};
</script>

<style>
</style>
