<template>
    <div>
        <b-row>
            <b-col>
                <b-form-group :label="$t('forms.tool')" label-for="tool1">
                    <b-form-input :name="$t('forms.tool')" v-model="tool1" disabled/>
                    <input type="hidden" :name="$t('forms.tool')" :value="tool1"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeNumber1.$errors.length }"
                    :label="$t('forms.aeNumber')"
                    label-for="aeNumber1"
                >
                    <b-form-input v-model="aeNumber1" :name="$t('forms.aeNumber')"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeNumber1.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.date1.$errors.length }"
                    :label="$t('forms.dateCalibration')"
                    label-for="date1"
                >
                    <datepicker
                        id="date1"
                        v-model="date1"
                        class="form-input"
                        :enableTimePicker="false"
                        format="dd.MM.yyyy"
                        :name="$t('forms.dateCalibration')"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.date1.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="$t('forms.tool')" label-for="tool2">
                    <b-form-input :name="$t('forms.tool')" v-model="tool2" disabled/>
                    <input type="hidden" :name="$t('forms.tool')" :value="tool2"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeNumber2.$errors.length }"
                    :label="$t('forms.aeNumber')"
                    label-for="aeNumber1"
                >
                    <b-form-input v-model="aeNumber2" :name="$t('forms.aeNumber')"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeNumber2.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.date2.$errors.length }"
                    :label="$t('forms.dateCalibration')"
                    label-for="date1"
                >
                    <datepicker
                        id="date2"
                        v-model="date2"
                        class="form-input"
                        :enableTimePicker="false"
                        :name="$t('forms.dateCalibration')"
                        format="dd.MM.yyyy"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.date2.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="$t('forms.tool')" label-for="tool3">
                    <b-form-input :name="$t('forms.tool')" v-model="tool3" disabled/>
                    <input type="hidden" :name="$t('forms.tool')" :value="tool3"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeNumber3.$errors.length }"
                    :label="$t('forms.aeNumber')"
                    label-for="aeNumber3"
                >
                    <b-form-input v-model="aeNumber3" :name="$t('forms.aeNumber')"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeNumber3.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.date3.$errors.length }"
                    :label="$t('forms.dateCalibration')"
                    label-for="date3"
                >
                    <datepicker
                        id="date3"
                        v-model="date3"
                        class="form-input"
                        :enableTimePicker="false"
                        :name="$t('forms.dateCalibration')"
                        format="dd.MM.yyyy"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.date3.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="$t('forms.tool')" label-for="tool4">
                    <b-form-input :name="$t('forms.tool')" v-model="tool4" disabled/>
                    <input type="hidden" :name="$t('forms.tool')" :value="tool4"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeNumber4.$errors.length }"
                    :label="$t('forms.aeNumber')"
                    label-for="aeNumber4"
                >
                    <b-form-input v-model="aeNumber4" :name="$t('forms.aeNumber')"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeNumber4.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.date4.$errors.length }"
                    :label="$t('forms.dateCalibration')"
                    label-for="date4"
                >
                    <datepicker
                        id="date4"
                        v-model="date4"
                        class="form-input"
                        :enableTimePicker="false"
                        :name="$t('forms.dateCalibration')"
                        format="dd.MM.yyyy"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.date4.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="$t('forms.tool')" label-for="tool5">
                    <b-form-input :name="$t('forms.tool')" v-model="tool5" disabled/>
                    <input type="hidden" :name="$t('forms.tool')" :value="tool5"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeNumber5.$errors.length }"
                    :label="$t('forms.aeNumber')"
                    label-for="aeNumber5"
                >
                    <b-form-input v-model="aeNumber5" :name="$t('forms.aeNumber')"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeNumber5.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.date5.$errors.length }"
                    :label="$t('forms.dateCalibration')"
                    label-for="date5"
                >
                    <datepicker
                        id="date5"
                        v-model="date5"
                        class="form-input"
                        :enableTimePicker="false"
                        :name="$t('forms.dateCalibration')"
                        format="dd.MM.yyyy"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.date5.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="$t('forms.tool')" label-for="tool6">
                    <b-form-input :name="$t('forms.tool')" v-model="tool6" disabled/>
                    <input type="hidden" :name="$t('forms.tool')" :value="tool6"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeNumber6.$errors.length }"
                    :label="$t('forms.aeNumber')"
                    label-for="aeNumber6"
                >
                    <b-form-input v-model="aeNumber6" :name="$t('forms.aeNumber')"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeNumber6.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.date6.$errors.length }"
                    :label="$t('forms.dateCalibration')"
                    label-for="date6"
                >
                    <datepicker
                        id="date6"
                        v-model="date6"
                        class="form-input"
                        :enableTimePicker="false"
                        :name="$t('forms.dateCalibration')"
                        format="dd.MM.yyyy"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.date6.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="$t('forms.tool')" label-for="tool7">
                    <b-form-input :name="$t('forms.tool')" v-model="tool7" disabled/>
                    <input type="hidden" :name="$t('forms.tool')" :value="tool7"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeNumber7.$errors.length }"
                    :label="$t('forms.aeNumber')"
                    label-for="aeNumber7"
                >
                    <b-form-input v-model="aeNumber7" :name="$t('forms.aeNumber')"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeNumber7.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.date7.$errors.length }"
                    :label="$t('forms.dateCalibration')"
                    label-for="date7"
                >
                    <datepicker
                        id="date7"
                        v-model="date7"
                        class="form-input"
                        :enableTimePicker="false"
                        :name="$t('forms.dateCalibration')"
                        format="dd.MM.yyyy"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.date7.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="$t('forms.tool')" label-for="tool8">
                    <b-form-input :name="$t('forms.tool')" v-model="tool8" disabled/>
                    <input type="hidden" :name="$t('forms.tool')" :value="tool8"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeNumber8.$errors.length }"
                    :label="$t('forms.aeNumber')"
                    label-for="aeNumber8"
                >
                    <b-form-input v-model="aeNumber8" :name="$t('forms.aeNumber')"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeNumber8.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.date8.$errors.length }"
                    :label="$t('forms.dateCalibration')"
                    label-for="date8"
                >
                    <datepicker
                        id="date8"
                        v-model="date8"
                        class="form-input"
                        :enableTimePicker="false"
                        :name="$t('forms.dateCalibration')"
                        format="dd.MM.yyyy"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.date8.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="$t('forms.tool')" label-for="tool9">
                    <b-form-input :name="$t('forms.tool')" v-model="tool9" disabled/>
                    <input type="hidden" :name="$t('forms.tool')" :value="tool9"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeNumber9.$errors.length }"
                    :label="$t('forms.aeNumber')"
                    label-for="aeNumber9"
                >
                    <b-form-input v-model="aeNumber9" :name="$t('forms.aeNumber')"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeNumber9.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.date9.$errors.length }"
                    :label="$t('forms.dateCalibration')"
                    label-for="date9"
                >
                    <datepicker
                        id="date9"
                        v-model="date9"
                        class="form-input"
                        :enableTimePicker="false"
                        :name="$t('forms.dateCalibration')"
                        format="dd.MM.yyyy"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.date9.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="$t('forms.tool')" label-for="tool10">
                    <b-form-input :name="$t('forms.tool')" v-model="tool10" disabled/>
                    <input type="hidden" :name="$t('forms.tool')" :value="tool10"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeNumber10.$errors.length }"
                    :label="$t('forms.aeNumber')"
                    label-for="aeNumber10"
                >
                    <b-form-input v-model="aeNumber10" :name="$t('forms.aeNumber')"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeNumber10.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.date10.$errors.length }"
                    :label="$t('forms.dateCalibration')"
                    label-for="date10"
                >
                    <datepicker
                        id="date10"
                        v-model="date10"
                        class="form-input"
                        :enableTimePicker="false"
                        :name="$t('forms.dateCalibration')"
                        format="dd.MM.yyyy"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.date10.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group :label="$t('forms.tool')" label-for="tool11">
                    <b-form-input :name="$t('forms.tool')" v-model="tool11" disabled/>
                    <input type="hidden" :name="$t('forms.tool')" :value="tool11"/>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.aeNumber11.$errors.length }"
                    :label="$t('forms.aeNumber')"
                    label-for="aeNumber11"
                >
                    <b-form-input v-model="aeNumber11" :name="$t('forms.aeNumber')"/>
                    <div
                        class="input-errors"
                        v-for="error of v$.aeNumber11.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    :class="{ row, error: v$.date11.$errors.length }"
                    :label="$t('forms.dateCalibration')"
                    label-for="date11"
                >
                    <datepicker
                        id="date11"
                        v-model="date11"
                        class="form-input"
                        :enableTimePicker="false"
                        :name="$t('forms.dateCalibration')"
                        format="dd.MM.yyyy"
                    />
                    <div
                        class="input-errors"
                        v-for="error of v$.date11.$errors"
                        :key="error.$uid"
                    >
                        <small class="text-danger">{{ error.$message }}</small>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
    components: {
        Datepicker,
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            aeNumber1: {required},
            date1: {required},
            aeNumber2: {required},
            date2: {required},
            aeNumber3: {required},
            date3: {required},
            aeNumber4: {required},
            date4: {required},
            aeNumber5: {required},
            date5: {required},
            aeNumber6: {required},
            date6: {required},
            aeNumber7: {required},
            date7: {required},
            aeNumber8: {required},
            date8: {required},
            aeNumber9: {required},
            date9: {required},
            aeNumber10: {required},
            date10: {required},
            aeNumber11: {required},
            date11: {required},
        };
    },
    data() {
        return {
            aeNumber1: null,
            date1: null,
            aeNumber2: null,
            date2: null,
            aeNumber3: null,
            date3: null,
            aeNumber4: null,
            date4: null,
            aeNumber5: null,
            date5: null,
            aeNumber6: null,
            date6: null,
            aeNumber7: null,
            date7: null,
            aeNumber8: null,
            date8: null,
            aeNumber9: null,
            date9: null,
            aeNumber10: null,
            date10: null,
            aeNumber11: null,
            date11: null,
            tool1: "Torque wrench QTS135",
            tool2: "Torque wrench QD1R50",
            tool3: "Torque wrench QD1R200",
            tool4: "Torque wrench QD2R1000",
            tool5: "Torque wrench QD3R250",
            tool6: "Torque wrench QD4R600",
            tool7: "Axle Jack",
            tool8: "HP set gauge",
            tool9: "Tire inflator gauge",
            tool10: "Tire pressure gauge",
            tool11: "Dial gauge",
        };
    },
};
</script>

<style>
</style>
