<template>
    <div>
        <b-form-group
            :class="{ row, error: v$.partNumber.$errors.length }"
            :label="$t('forms.partNumber')"
            label-for="partNumber"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="partNumber" :name="$t('forms.partNumber')"/>
            <div
                class="input-errors"
                v-for="error of v$.partNumber.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <b-form-group
            :class="{ row, error: v$.serialNumber.$errors.length }"
            :label="$t('forms.serialNumber')"
            label-for="serialNumber"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="serialNumber" :name="$t('forms.serialNumber')"/>
            <div
                class="input-errors"
                v-for="error of v$.serialNumber.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <b-form-group
            :class="{ row, error: v$.desc.$errors.length }"
            :label="$t('forms.description')"
            label-for="desc"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="desc" :name="$t('forms.description')"/>
            <div
                class="input-errors"
                v-for="error of v$.desc.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <b-form-group
            :class="{ row, error: v$.toolStatus.$errors.length }"
            :label="$t('forms.toolStatus')"
            label-for="desc"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-radio
                v-model="toolStatus"
                :name="$t('forms.toolStatus')"
                value="Tool broken"
            >
                Tool broken
            </b-form-radio>
            <b-form-radio
                v-model="toolStatus"
                :name="$t('forms.toolStatus')"
                value="Tool lost"
            >
                Tool lost
            </b-form-radio>
            <b-form-radio
                v-model="toolStatus"
                :name="$t('forms.toolStatus')"
                value="Tool missing"
            >
                Tool missing
            </b-form-radio>
            <div
                class="input-errors"
                v-for="error of v$.toolStatus.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <b-form-group
            :class="{ row, error: v$.missingFrom.$errors.length }"
            :label="$t('forms.missingFrom')"
            label-for="missingFrom"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <datepicker id="missingFrom" v-model="missingFrom" class="form-input" :name="$t('forms.missingFrom')"
                        format="dd.MM.yyyy HH:mm"/>
            <div
                class="input-errors"
                v-for="error of v$.missingFrom.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <b-form-group
            :class="{ row, error: v$.lastUsed.$errors.length }"
            :label="$t('forms.lastUsed')"
            label-for="lastUsed"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="lastUsed" :name="$t('forms.lastUsed')"/>
            <div
                class="input-errors"
                v-for="error of v$.lastUsed.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
        <b-form-group
            :class="{ row, error: v$.checkedPlaces.$errors.length }"
            :label="$t('forms.checkedPlaces')"
            label-for="checkedPlaces"
            label-cols-md="2"
            content-cols-lg="4"
        >
            <b-form-input v-model="checkedPlaces" :name="$t('forms.checkedPlaces')"/>
            <div
                class="input-errors"
                v-for="error of v$.checkedPlaces.$errors"
                :key="error.$uid"
            >
                <small class="text-danger">{{ error.$message }}</small>
            </div>
        </b-form-group>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import Datepicker from "@vuepic/vue-datepicker";
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    components: {
        Datepicker,
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            partNumber: {required},
            serialNumber: {required},
            desc: {required},
            toolStatus: {required},
            missingFrom: {required},
            lastUsed: {required},
            checkedPlaces: {required}
        };
    },
    data() {
        return {
            partNumber: null,
            serialNumber: null,
            desc: null,
            toolStatus: null,
            missingFrom: null,
            lastUsed: null,
            checkedPlaces: null
        };
    },
};
</script>

<style>
</style>
