<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit" ref="formRef" id="formRef">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        :class="{ row, error: v$.stationSelect.$errors.length }"
                        :label="$t('forms.station')"
                        label-for="h-station"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-station"
                            v-model="stationSelect"
                            :options="stations"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stationSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.remark')"
                        label-for="remark"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input v-model="remark" id="remark" name="remark"/>
                    </b-form-group>

                    <hr>
                    <component :is="formMap[$route.params.id]"/>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import vSelect from "vue-select";
import ToolReport from "./ToolReport.vue";
import ProductionPlanning from "./ProductionPlanning.vue";
import CalibrationTooling from "./CalibrationTooling.vue";
import TripHandover from "./TripHandover.vue";
import DailyHandover from "./DailyHandover.vue";

export default {
    components: {
        vSelect,
        ToolReport,
        ProductionPlanning,
        CalibrationTooling,
        TripHandover,
        DailyHandover
    },
    props: {
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getResourceList(this.$Stations, this.stations);
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            stationSelect: {required},
        };
    },
    data() {
        return {
            stations: [],
            stationSelect: {},
            remark: null,
            formMap: {
                1: "ToolReport",
                2: "ProductionPlanning",
                3: "CalibrationTooling",
                4: "TripHandover",
                5: "DailyHandover"
            },
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.action === "add") {
                    let formEl = document.forms.formRef;
                    let formData = new FormData(formEl);
                    let formString = new URLSearchParams(formData).toString();
                    formString += "&station=" + this.stationSelect.label;
                    const body = {
                        type: "/form_types/" + this.$route.params.id,
                        data: formString,
                        station: this.stationSelect.id,
                        remark: this.remark
                    };
                    this.create(
                        this.$Forms,
                        body,
                        this.$t("messages.formCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        success() {
            this.$router.push(`/forms`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

